type FetchErrorOptions = ErrorOptions & {
  isNetworkError: boolean;
  status?: number;
  statusText?: string;
  // TODO #499: use unknown instead https://github.com/lisegmbh/hessen-mobil-e21x-app-store/issues/499
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any;
};

export class FetchError extends Error {
  isNetworkError: boolean;
  status: number | undefined;
  statusText: string | undefined;
  // TODO #499: use unknown instead
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: any;

  constructor(message: string, options: FetchErrorOptions) {
    const { isNetworkError, status, statusText, body, ...errorOptions } =
      options ?? {};
    super(message, errorOptions);
    this.isNetworkError = isNetworkError;
    this.status = status;
    this.statusText = statusText;
    this.body = body;
  }

  isUnauthorizedError(): boolean {
    return this.status === 401;
  }

  toString() {
    return `${this.status} ${this.statusText}`;
  }
}
