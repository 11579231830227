import { FetchError } from "./FetchError";
import { hasJsonBody, useDefaultHeaders } from "./CustomFetchUtils";

export default function useCustomFetch() {
  const headers = useDefaultHeaders();

  return async function customFetch(path: string, init?: RequestInit) {
    let response: Response;
    try {
      response = await fetch(path, {
        headers,
        ...init,
      });
    } catch (err) {
      throw new FetchError(`Failed to make request`, {
        cause: err,
        isNetworkError: true,
      });
    }
    if (!response.ok) {
      throw new FetchError(
        `Network response was ${response.status} ${response.statusText}`,
        {
          isNetworkError: false,
          status: response.status,
          statusText: response.statusText,
          body: hasJsonBody(response) ? await response.json() : undefined,
        },
      );
    }
    if (hasJsonBody(response)) {
      return response.json();
    }
    return response;
  };
}
