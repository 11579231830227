import AppGroup from "../react-query/app-group/AppGroup";
import { useState } from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import ErrorMessage from "../ui/ErrorMessage";
import { usePostMutation } from "../react-query/mutation-queries/usePostMutation";
import MutationKey from "../react-query/mutation-queries/MutationKey";
import MutationPath from "../react-query/mutation-queries/MutationPath";
import { useQueryClient } from "@tanstack/react-query";
import ServerStateKeysEnum from "../react-query/ServerStatekeysEnum";
import ValidationErrorMessages from "./ValidationErrorMessages";
import { useAuthUser } from "../auth/useAuthUser";
import "react-toastify/dist/ReactToastify.css";
import { configurationToastMessage } from "../ui/configurationToastMessage";

interface ConfigurationEditorProps {
  data: AppGroup[];
}

export default function ConfigurationEditor({
  data,
}: ConfigurationEditorProps) {
  const [groups, setGroups] = useState<string>(
    JSON.stringify(data, undefined, 2),
  );
  const [isJsonParsingError, setIsJsonParsingError] = useState(false);
  const queryClient = useQueryClient();
  const { hasRole } = useAuthUser();

  const hasPermissionToEdit = hasRole("AppStoreKonfiguration_schreiben");

  const {
    mutate,
    isPending: isMutationLoading,
    isError: isMutationError,
    error: mutationError,
  } = usePostMutation<AppGroup[], AppGroup[]>(MutationKey.PostConfiguration, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ServerStateKeysEnum.Configuration],
      });
      configurationToastMessage(true);
    },
    onError: () => {
      configurationToastMessage(false);
    },
  });

  function handleSave() {
    let body = null;
    try {
      body = JSON.parse(groups);
      setIsJsonParsingError(false);
    } catch (err) {
      console.error(err);
      setIsJsonParsingError(true);
      configurationToastMessage(false);
      return;
    }

    mutate({ body, path: MutationPath.CreateConfiguration });
  }

  const JsonParsingError = isJsonParsingError && (
    <ErrorMessage title="Fehler in der Konfiguration">
      <div>Kein gültiges JSON-Format!</div>
    </ErrorMessage>
  );

  const MutationErrorMessage = !isJsonParsingError && isMutationError && (
    <ErrorMessage title="Fehler in der Konfiguration">
      <>
        <div>{mutationError?.body?.message}</div>
        <ul>
          {mutationError?.body?.errors && (
            <ValidationErrorMessages
              errors={mutationError.body.errors}
            ></ValidationErrorMessages>
          )}
        </ul>
      </>
    </ErrorMessage>
  );

  return (
    <>
      <AceEditor
        value={groups}
        onChange={setGroups}
        mode="json"
        width="1200px"
        height="800px"
        setOptions={{ useWorker: false }}
        readOnly={!hasPermissionToEdit}
      />
      {hasPermissionToEdit && (
        <>
          {JsonParsingError}
          {MutationErrorMessage}
          <div className="mt-4 is-flex is-justify-content-end is-align-items-center">
            <button
              className="button is-success"
              onClick={handleSave}
              disabled={isMutationLoading}
            >
              <span className="icon is-small">
                <i className="fas fa-check" />
              </span>
              <span>Speichern</span>
            </button>
          </div>
        </>
      )}
    </>
  );
}
